<template>
  <div>
    <form method="post" name="LGD_PAYINFO" id="LGD_PAYINFO">
        <input type="hidden" :name="name" :id="name" v-for="(data, name, idx) in lgd_datas" :value="data" v-bind:key="idx"/>
    </form>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>Point</h2>
          </div>
          <section class="study_myinfo_list_area" style="margin-top:-16px;" >
            <div class="mypage_scroll_content">
              <section class="study_main_box">
                <!-- content -->
                  <!-- 시리즈 학습일정 -->
                  <div style="width:100%;">
                    <div class="mypage_top_btn_wrap">
                      <!-- 포인트 잔액 -->
                      <div class="mypage_bank_btn_wrap">
                      <div class="mypage_bank_btn">
                        <div class="mypage_bank_h4_title">
                          <h4>포인트 잔액</h4>
                        </div>
                        <div class="mypage_bank_num">{{ balance }}</div>
                      </div>
                      </div>
                      <!-- //포인트 잔액 -->
                      <!-- 포인트 충전 -->
                      <div class="mypage_charge_btn_wrap">
                        <div class="mypage_charge_btn">
                          <div class="mypage_charge_h4title">포인트 충전</div>
                        </div>
                      </div>
                      <!-- //포인트 충전 -->
                    </div>
                    <!-- 포인트 사용내역 -->
                    <div class="mypage_use_list">
                      <div class="mypage_use_title">
                        포인트 사용내역
                      </div>
                      <!-- <ul @click.prevent="$router.replace({ name: 'notification', query: { redirect: 'my-page' } })" class="clickable"> -->
                      <ul>
                        <li v-for="(point, idx) in pointHistories" v-bind:key="idx">
                          <a class="mypage_use_list_text" href="#">{{ point.series.name }} 수강료</a>
                          <div class="mypage_use_list_btn" v-text="point.enrollment_date"></div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!--// 포인트 사용내역 -->
                  <!-- 충전금액 -->
                  <div class="point_wrap">
                    <dl>
                      <dt>충전 금액</dt>
                      <dd><input v-model="amount" class="mk-btn-purchase-credit-btn"></dd>
                    </dl>
                    <div>
                      <div class="point_charge_btn clickable"
                        @click.prevent="purchase"
                        :disabled="amount <= 0"
                      >
                        <p class="point_charge_btn_title">충전</p>
                        <div class="record_btn_click_area">
                          <img src="/image/sub/study_record_btn04.png" alt="클릭 아이콘">
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- //충전금액 -->
                <!-- //content -->
              </section>
              <div v-if="this.redirect === 'program.level.class-per-week'">
                <br/>
                <br/>
                <h3>The enrollment can only continue once the payment is completed</h3>
                <br/>
              </div>
              <div v-if="this.redirect === 'program.level.class-per-week.set-video-class'">
                <br/>
                <br/>
                <h3>Successfully Paid</h3>
                <br/>
                <button style="padding: 5px;" @click.prevent="continueEnrollment">
                  Continue Enrollment
                </button>
                <br/>
              </div>
            </div>
          </section>
        </section>
        <footer-navigation :back="redirectUrl"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>

import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import User from '@/models/User'
import Credits from '@/models/Credits'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  name: 'MyPage.Point',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  data () {
    return {
      platform: 'test',
      balance: 0,
      amount: typeof this.$route.query.amount !== 'undefined' ? this.$route.query.amount : '0',
      lgd_datas: [],
      filter: 'win16|win32|win64|mac|macintel',
      isMobile: false,
      lgdwin: '',
      redirectUrl: {
        name: 'my-page'
      },
      redirect: typeof this.$route.query.redirect !== 'undefined' ? this.$route.query.redirect : '',
      errorMessage: typeof this.$route.query.errorMessage !== 'undefined' ? this.$route.query.errorMessage : '',
      levelId: typeof this.$route.query.levelId !== 'undefined' ? this.$route.query.levelId : '',
      classPerWeekId: typeof this.$route.query.classPerWeekId !== 'undefined' ? this.$route.query.classPerWeekId : '',
      productId: typeof this.$route.query.productId !== 'undefined' ? this.$route.query.productId : '',
      oneOnOneClass: typeof this.$route.query.oneOnOneClass !== 'undefined' ? this.$route.query.oneOnOneClass : '',
      pointHistories: []
    }
  },
  mounted () {
    this.loadMyProfile()
    if (navigator.platform) {
      if (this.filter.indexOf(navigator.platform.toLowerCase()) < 0) {
        this.isMobile = true
      }
    }
    if (this.redirect === 'program.level.class-per-week') {
      this.redirectUrl = {
        name: 'program.level.class-per-week',
        params: { levelId: this.levelId }
      }
    } else {
      this.redirectUrl = {
        name: 'my-page'
      }
    }

    if (this.errorMessage !== '') {
      this.$swal({
        title: 'Error',
        text: this.errorMessage,
        icon: 'error'
      })
    }
  },
  methods: {
    async loadMyProfile () {
      const response = await User.myProfile()
      this.balance = response.data.amount
      this.pointHistories = response.data.enrollments
    },
    continueEnrollment () {
      this.$router.push({ name: 'program.level.class-per-week.set-video-class', params: { levelId: this.levelId, classPerWeekId: this.classPerWeekId }, query: { oneOnOneClass: this.oneOnOneClass } })
    },
    async purchase () {
      const confirm = await this.$swal({
        title: 'Are you sure?',
        text: 'You want to purchase!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        className: 'swal2-styled',
        customClass: {
          confirmButton: 'swal2-confirm btn btn-primary swal2-styled',
          cancelButton: 'swal2-cancel swal2-styled'
        },
        buttonsStyling: false
      })

      if (confirm.value) {
        try {
          const customData = {}
          if (this.levelId !== '') {
            customData.levelId = this.levelId
          }
          if (this.classPerWeekId !== '') {
            customData.classPerWeekId = this.classPerWeekId
          }
          const response = await Credits.purchase({ amount: this.amount, cas_note_url: window.location.origin + '/cas-note-url.php', return_url: window.location.origin + '/return-url.php', customData: customData })
          if (typeof response.data.LGD_PAYINFOS !== 'undefined') {
            this.lgd_datas = response.data.LGD_PAYINFOS
            this.platform = response.data.platform
            this.launchCrossPlatform()
          } else {
            this.$swal({
              title: 'Error',
              text: response.data.message,
              icon: 'error'
            })
          }
        } catch (e) {
          this.$swal({
            title: 'Error',
            text: e.response.data.message,
            icon: 'error'
          })
        }
      }
    },
    launchCrossPlatform () {
      // eslint-disable-next-line
      if ($('#LGD_PAYINFO input').length === Object.keys(this.lgd_datas).length) {
        if (!this.isMobile) {
          // eslint-disable-next-line
          this.lgdwin = openXpay(document.getElementById('LGD_PAYINFO'), this.platform, 'iframe', null, '', '')
        } else {
          // eslint-disable-next-line
          this.lgdwin = open_paymentwindow(document.getElementById('LGD_PAYINFO'), this.platform, 'submit')
        }
      } else {
        const self = this
        setTimeout(function () {
          self.launchCrossPlatform()
        }, 100)
      }
    }
  }
}
</script>
<style scoped>

</style>
